export default {
  sm: 100,
  md: 250,
  lg: 500,
  seconds: {
    sm: 0.1,
    md: 0.25,
    lg: 0.5,
  },
};
